import 'babel-polyfill'
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { BootstrapVue } from 'bootstrap-vue'
import Loading from 'vue-loading-overlay'
import MultiSelect from 'vue-multiselect'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.use(Vuex)

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)

Vue.component('Loading', Loading)
Vue.component('MultiSelect', MultiSelect)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
