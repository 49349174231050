import api from '../../api'

const state = () => ({
  list: undefined,
  view: undefined,
  edit: undefined,
  taskRecords: undefined
})

const mutations = {
  setList(state, payload) {
    state.list = payload
  },
  setView(state, payload) {
    state.view = payload
  },
  initEdit(state) {
    state.edit = {
      facebookAccountId: undefined,
      facebookAdAccountId: undefined,
      googleAccountId: undefined,
      sa360AccountId: undefined,
      sftpUsername: undefined,
      sftpPassword: undefined,
      timezone: undefined
    }
  },
  setEdit(state, payload) {
    const { facebookAdAccount, sa360Account, timezone } = payload
    state.edit.facebookAccountId = facebookAdAccount.facebookAccount.id
    state.edit.facebookAdAccountId = facebookAdAccount.id
    state.edit.googleAccountId = sa360Account.googleAccount.id
    state.edit.sa360AccountId = sa360Account.id
    state.edit.sftpUsername = sa360Account.sftpUsername
    state.edit.timezone = timezone
  },
  setEditSetup(state, payload) {
    state.edit[payload.type] = payload.value
  },
  setTaskRecords(state, payload) {
    state.taskRecords = payload
  }
}

const actions = {
  async getList({ commit }) {
    try {
      const limit = 100
      let response = await api.getConnections(limit)
      let total = response.data.total
      if (total > limit) {
        response = await api.getConnections(total)
      }
      commit('setList', response.data.results)
      return response.status
    } catch (err) {
      console.error('Connection - getList: ' + err)
      return 'error'
    }
  },
  async getView({ commit }, payload) {
    try {
      let response = await api.getConnection(payload.id)
      commit('setView', response.data)
      return response.status
    } catch (err) {
      console.error('Connection - getView: ' + err)
      return 'error'
    }
  },
  async initEdit({ commit }) {
    commit('initEdit')
    // commit('facebook/setAccounts', undefined, { root: true })
    commit('facebook/setAdAccounts', undefined, { root: true })
    commit('google/setAccounts', undefined, { root: true })
    commit('google/setSa360Accounts', undefined, { root: true })
  },
  async createHandler({ state }) {
    try {
      const data = {
        facebookAdAccountId: state.edit.facebookAdAccountId,
        sa360AccountId: state.edit.sa360AccountId,
        sftpUsername: state.edit.sftpUsername,
        sftpPassword: state.edit.sftpPassword
      }
      let response = await api.createConnection(data)
      return response
    } catch (error) {
      console.error('Connection - updateHandler: ' + error)
      return error
    }
  },
  async updateHandler({ state }, payload) {
    try {
      const data = {
        sftpUsername: state.edit.sftpUsername,
        sftpPassword: state.edit.sftpPassword
      }
      let response = await api.updateConnection(payload.id, data)
      return response
    } catch (error) {
      console.error('Connection - updateHandler: ' + error)
      return error
    }
  },
  async deleteHandler(store, payload) {
    try {
      let response = await api.deleteConnection(payload.id)
      return response.status
    } catch (err) {
      console.error('Connection - deleteHandler: ' + err)
      return 'error'
    }
  },
  async changeStatusHandler(store, payload) {
    try {
      const { id, status } = payload
      let response = await api.updateConnection(id, { status })
      return response.status
    } catch (err) {
      console.error('Connection - changeStatusHandler: ' + err)
      return 'error'
    }
  },
  async getTaskRecords({ commit }, payload) {
    try {
      const limit = 100
      let response = await api.getConnectionTaskRecords(payload.id, limit)
      const total = response.data.total
      if (total > limit) {
        response = await api.getConnectionTaskRecords(total)
      }
      commit('setTaskRecords', response.data.results)
      return response.status
    } catch (err) {
      console.error('Connection - getTaskRecords: ' + err)
      return 'error'
    }
  },
  async retryTaskRecords(store, payload) {
    try {
      const { id, recordId } = payload
      let response = await api.retryConnectionTaskRecords({ id, recordId })

      return response.status
    } catch (err) {
      console.error('Connection - retryTaskRecords: ' + err)
      return 'error'
    }
  },
  // Page - Home
  async pageHomeCreated({ state, dispatch }) {
    try {
      dispatch('progressStart', null, { root: true })

      if (!state.list) {
        let status = await dispatch('getList')
        if (status !== 200) throw 'error'
      }

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('User - pageHomeCreated: ' + err)
      return 'error'
    }
  },
  async pageHomeStatusHandler({ dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })

      const { id } = payload
      let status = payload.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
      let responseStatus = await dispatch('changeStatusHandler', { id, status })
      if (responseStatus !== 200) throw 'error'

      let getListStatus = await dispatch('getList')
      if (getListStatus !== 200) throw 'error'

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('Connection - pageHomeStatusHandler: ' + err)
      return 'error'
    }
  },
  // Page - View
  async pageViewCreated({ state, commit, dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })

      const { id } = payload
      if (state.list) {
        let connection = state.list.filter(x => x.id === id)
        commit('setView', connection[0])
      } else {
        let status = await dispatch('getView', { id })
        if (status !== 200) throw 'error'
      }

      let taskRecordsStatus = await dispatch('getTaskRecords', { id })
      if (taskRecordsStatus !== 200) throw 'error'

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('Connection - pageViewCreated: ' + err)
      return 'error'
    }
  },
  async pageViewStatusHandler({ dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })

      const { id } = payload
      let status = payload.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
      let responseStatus = await dispatch('changeStatusHandler', { id, status })
      if (responseStatus !== 200) throw 'error'

      await dispatch('getView', { id })
      await dispatch('getList')

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('Connection - pageViewStatusHandler: ' + err)
      return 'error'
    }
  },
  async pageViewRecordRetryHandler({ dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })

      const { id, recordId } = payload
      let response = await dispatch('retryTaskRecords', { id, recordId })
      if (response !== 201) throw 'error'

      await dispatch('getTaskRecords', { id })

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('Connection - pageViewRecordRetryHandler: ' + err)
      return 'error'
    }
  },
  // Page - Setup
  async pageSetupCreated({ state, commit, dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })
      dispatch('initEdit')

      const { id } = payload
      let response = 200
      if (id) {
        // EDIT CONNECTION
        response = await dispatch('getView', { id })
        commit('setEdit', state.view)
        // )
      } else {
        // NEW CONNECTION

        await dispatch('facebook/getAccountDefault', null, {
          root: true
        })
        await dispatch('google/getAccountDefault', null, {
          root: true
        })
      }

      dispatch('progressEnd', null, { root: true })
      return response === 200 ? 'success' : 'error'
    } catch (err) {
      console.error('Connection - setupCreated: ' + err)
      return 'error'
    }
  },
  async pageSetupSubmit({ dispatch }, payload) {
    dispatch('progressStart', null, { root: true })

    const { status, id } = payload
    let response = undefined
    let result = 'error'
    if (status === 'New') {
      response = await dispatch('createHandler')
    } else if (status === 'Edit') {
      response = await dispatch('updateHandler', { id })
    }

    if (response.status === 200 || response.status === 201) {
      await dispatch('getList')
    }

    dispatch('progressEnd', null, { root: true })
    if (response.status === 200 || response.status === 201) {
      result = 'success'
    } else if (response.response) {
      result = response.response.data.message
    }
    return result
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
