import api from '../../api'

const state = () => ({
  accounts: undefined,
  sa360Accounts: undefined
})

const mutations = {
  setAccounts(state, payload) {
    state.accounts = payload
  },
  setSa360Accounts(state, payload) {
    state.sa360Accounts = payload
  }
}

const actions = {
  async getOauthStart() {
    try {
      let response = await api.getGoogleOAuthStart()
      // console.log(response)
      return response
    } catch (err) {
      console.error('Google - getOauthStart: ' + err)
      return 'error'
    }
  },
  async getAccounts({ commit }) {
    try {
      const limit = 100
      let response = await api.getGoogleAccounts(limit)
      let total = response.data.total
      if (total > limit) {
        response = await api.getGoogleAccounts(total)
      }
      commit('setAccounts', response.data.results)
      return response.status
    } catch (err) {
      console.error('Google - getAccounts: ' + err)
      return 'error'
    }
  },
  async getSa360Accounts({ commit }, payload) {
    try {
      const { id } = payload
      const limit = 100
      let response = await api.getGoogleSA360Accounts(id, limit)
      let total = response.data.total
      if (total > limit) {
        response = await api.getGoogleSA360Accounts(id, total)
      }
      commit('setSa360Accounts', response.data.results)
      return response.status
    } catch (err) {
      console.error('Google - getSa360Accounts: ' + err)
      commit('setSa360Accounts', [])
      return 'error'
    }
  },
  async deleteAccount(store, payload) {
    try {
      const { id } = payload
      let response = await api.deleteGoogleAccount(id)
      return response.status
    } catch (err) {
      console.error('Google - deleteAccount: ' + err)
      return 'error'
    }
  },
  // Page - ConnectionSetup
  async getAccountDefault({ state, commit, dispatch }) {
    try {
      await dispatch('getAccounts')
      if (state.accounts.length) {
        commit(
          'connection/setEditSetup',
          {
            type: 'googleAccountId',
            value: state.accounts[0].id
          },
          { root: true }
        )
        await dispatch('getSa360Accounts', {
          id: state.accounts[0].id
        })
      }
      return 'success'
    } catch (err) {
      console.error('Facebook - getAccountDefault: ' + err)
      return 'error'
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
