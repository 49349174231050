import axios from 'axios'

const apiPath = process.env.VUE_APP_API

axios.defaults.baseURL = `${apiPath}/v1`
axios.defaults.headers['Access-Control-Allow-Origin'] = 'http://localhost:8080'
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.withCredentials = true

export default {
  async getToken() {
    let token = axios.defaults.headers.common['X-CSRF-TOKEN']
    if (!token) {
      await this.initToken()
      // console.log('GET Token : ' + token)
      return 'done'
    } else {
      // console.log('HAS Token : ' + token)
      return 'done'
    }
  },
  async initToken() {
    try {
      let response = await axios.get(`${apiPath}/v1/init`)
      axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrfToken
      return response.data.csrfToken
    } catch (err) {
      console.error(err)
      return 'err'
    }
  },
  login(payload) {
    return axios.post('/auth/login', payload)
  },
  logout() {
    return axios.get('/auth/logout')
  },
  resetPassword(payload) {
    return axios.post('/auth/reset-password', payload)
  },
  // Users
  getUserSelf() {
    return axios.get('/users/self')
  },
  getUsers(limit) {
    return axios.get(`/users?limit=${limit}`)
  },
  getUser(id) {
    return axios.get(`/users/${id}`)
  },
  createUser(payload) {
    return axios.post('/users', payload)
  },
  updateUser(id, payload) {
    return axios.patch(`/users/${id}`, payload)
  },
  deleteUser(id) {
    return axios.delete(`/users/${id}`)
  },
  // Subscriptions
  getSubscriptions(limit) {
    return axios.get(`/subscriptions?limit=${limit}`)
  },
  getSubscription(id) {
    return axios.get(`/subscriptions/${id}`)
  },
  createSubscription(payload) {
    return axios.post(`/subscriptions`, payload)
  },
  updateSubscription(id, payload) {
    return axios.patch(`/subscriptions/${id}`, payload)
  },
  deleteSubscription(id) {
    return axios.delete(`/subscriptions/${id}`)
  },
  getSubscriptionUserList(id, limit) {
    return axios.get(`/subscriptions/${id}/users?limit=${limit}`)
  },
  // Fivestones Staff
  getFivestonesStaff(limit) {
    return axios.get(`/fivestones/staff?limit=${limit}`)
  },
  // Connections
  getConnections(limit) {
    return axios.get(`/connections?limit=${limit}`)
  },
  createConnection(payload) {
    return axios.post(`/connections`, payload)
  },
  getConnection(id, payload) {
    return axios.get(`/connections/${id}`, payload)
  },
  deleteConnection(id) {
    return axios.delete(`/connections/${id}`)
  },
  updateConnection(id, payload) {
    return axios.patch(`/connections/${id}`, payload)
  },
  getConnectionTaskRecords(id, limit) {
    return axios.get(`/connections/${id}/task-records?limit=${limit}`)
  },
  retryConnectionTaskRecords({ id, recordId }) {
    return axios.post(`/connections/${id}/task-records/${recordId}/retry`)
  },
  // Facebook
  getFacebookOAuthStart() {
    return axios.post(`/facebook/oauth/start`, { type: 'AUTHORISATE' })
  },
  getFacebookAccounts(limit) {
    return axios.get(`/facebook-accounts?limit=${limit}`)
  },
  getFacebookAdAccounts(id, limit) {
    return axios.get(`/facebook-accounts/${id}/ad-accounts?limit=${limit}`)
  },
  deleteFacebookAccount(id) {
    return axios.delete(`/facebook-accounts/${id}`)
  },
  // Google
  getGoogleOAuthStart() {
    return axios.post(`/google/oauth/start`)
  },
  getGoogleAccounts() {
    return axios.get(`/google-accounts`)
  },
  getGoogleSA360Accounts(id) {
    return axios.get(`/google-accounts/${id}/sa360-accounts`)
  },
  deleteGoogleAccount(id) {
    return axios.delete(`/google-accounts/${id}`)
  },
  // History
  getHistories(limit) {
    return axios.get(`/histories?limit=${limit}`)
  }
}
