const actions = {
  // Page - Home
  async pageHomeCreated({ dispatch, rootState }) {
    try {
      dispatch('progressStart', null, { root: true })

      const facebookAccounts = rootState.facebook.accounts
      const googleAccounts = rootState.google.accounts

      if (!facebookAccounts) {
        let facebookResponse = await dispatch('facebook/getAccounts', null, {
          root: true
        })
        if (facebookResponse !== 200) throw 'error'
      }

      if (!googleAccounts) {
        let googleResponse = await dispatch('google/getAccounts', null, {
          root: true
        })
        if (googleResponse !== 200) throw 'error'
      }

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('AccountAuth - pageHomeCreated: ' + err)
      return 'error'
    }
  },
  async pageGetAccount({ dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })

      const { type } = payload
      let status = await dispatch(`${type}/getAccounts`, null, {
        root: true
      })
      if (status !== 200) throw 'error'

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('AccountAuth - pageGetAccount: ' + err)
      return 'error'
    }
  },
  async pageRevokeAccount({ dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })
      const { type, id } = payload

      let status = await dispatch(
        `${type}/deleteAccount`,
        { id },
        { root: true }
      )
      if (status !== 200) throw 'error'
      await dispatch(`${type}/getAccounts`, null, {
        root: true
      })

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('AccountAuth - pageRevokeAccount: ' + err)
      return 'error'
    }
  }
}
export default {
  namespaced: true,
  actions
}
