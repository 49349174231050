import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
const { state, dispatch } = store
// Containers
const Container = () => import('@/views/containers/Containers.vue')
const PagesContainer = () => import('@/views/containers/PagesContainer.vue')

// Pages
const Index = () => import('@/views/pages/Index.vue')
const Connections = () => import('@/views/pages/Connections.vue')
const ConnectionsView = () => import('@/views/pages/ConnectionsView.vue')
const ConnectionsSetup = () => import('@/views/pages/ConnectionsSetup.vue')
const AccountAuth = () => import('@/views/pages/AccountAuth.vue')
const UserInfo = () => import('@/views/pages/UserInfo.vue')
const History = () => import('@/views/pages/History.vue')
const Subscription = () => import('@/views/pages/Subscription.vue')
const SubscriptionView = () => import('@/views/pages/SubscriptionView.vue')
const SubscriptionSetup = () => import('@/views/pages/SubscriptionSetup.vue')
const User = () => import('@/views/pages/User.vue')
const UserSetup = () => import('@/views/pages/UserSetup.vue')

const Page403 = () => import('@/views/pages/Page403.vue')
const Page404 = () => import('@/views/pages/Page404.vue')
const Login = () => import('@/views/pages/Login.vue')
const PasswordSetup = () => import('@/views/pages/PasswordSetup.vue')
const PasswordReset = () => import('@/views/pages/PasswordReset.vue')
const AuthResponse = () => import('@/views/pages/AuthResponse.vue')
const AuthResponseTest = () => import('@/views/pages/AuthResponseTest.vue')

const configRoutes = () => {
  return [
    {
      path: '/',
      name: 'index',
      alias: '/index',
      meta: { requiresAuth: false },
      component: Index
    },
    {
      path: '/',
      component: Container,
      children: [
        {
          path: '/connections',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/',
              name: 'Connections',
              meta: { requiresAuth: true },
              component: Connections
            },
            {
              path: `:id/view`,
              name: 'ConnectionsView',
              meta: { requiresAuth: true },
              component: ConnectionsView
            },
            {
              path: `:id/edit`,
              name: 'ConnectionsEdit',
              meta: { requiresAuth: true },
              component: ConnectionsSetup
            },
            {
              path: 'new',
              name: 'ConnectionsNew',
              meta: { requiresAuth: true },
              component: ConnectionsSetup
            },
            { path: '*', redirect: '/pages/page404' }
          ]
        },
        {
          path: '/account-auth',
          redirect: '/account-auth/google',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: `:accountType(facebook|google)`,
              name: 'AccountAuth',
              meta: { requiresAuth: true },
              component: AccountAuth
            },
            { path: '*', redirect: '/pages/page404' }
          ]
        },
        {
          path: 'user-info',
          name: 'UserInfo',
          meta: { requiresAuth: true, isAdmin: false },
          component: UserInfo
        },
        {
          path: 'user-info/*',
          redirect: '/pages/page404'
        },
        {
          path: 'history',
          name: 'History',
          meta: { requiresAuth: true },
          component: History
        },
        {
          path: 'history/*',
          redirect: '/pages/page404'
        },
        {
          path: 'platform',
          redirect: '/platform/subscription',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'subscription',
              name: 'Subscription',
              meta: { requiresAuth: true, isAdmin: true },
              component: Subscription
            },
            {
              path: 'subscription/:id/view',
              name: 'SubscriptionView',
              meta: { requiresAuth: true, isAdmin: true },
              component: SubscriptionView
            },
            {
              path: 'subscription/:id/edit',
              name: 'SubscriptionEdit',
              meta: { requiresAuth: true, isAdmin: true },
              component: SubscriptionSetup
            },
            {
              path: 'subscription/new',
              name: 'SubscriptionNew',
              meta: { requiresAuth: true, isAdmin: true },
              component: SubscriptionSetup
            },
            {
              path: 'user',
              name: 'User',
              meta: { requiresAuth: true, isAdmin: true },
              component: User
            },
            {
              path: 'user/:id/edit',
              name: 'UserEdit',
              meta: { requiresAuth: true, isAdmin: true },
              component: UserSetup
            },
            {
              path: 'user/new',
              name: 'UserNew',
              meta: { requiresAuth: true, isAdmin: true },
              component: UserSetup
            },
            { path: '*', redirect: '/pages/page404' }
          ]
        }
      ]
    },
    {
      path: '/pages',
      component: PagesContainer,
      children: [
        {
          path: '/',
          name: 'Pages',
          meta: { requiresAuth: false },
          component: Page404
        },
        {
          path: 'page403',
          name: 'Page403',
          meta: { requiresAuth: false },
          component: Page403
        },
        {
          path: 'page404',
          name: 'Page404',
          meta: { requiresAuth: false },
          component: Page404
        },
        {
          path: 'login',
          name: 'Login',
          meta: { requiresAuth: false },
          component: Login
        },
        {
          path: 'password/setup',
          name: 'PasswordSetup',
          meta: { requiresAuth: true },
          component: PasswordSetup
        },
        {
          path: 'password/reset',
          name: 'PasswordReset',
          meta: { requiresAuth: false },
          component: PasswordReset
        },
        {
          path: 'authResponse',
          name: 'AuthResponse',
          meta: { requiresAuth: false },
          component: AuthResponse
        },
        // TEST
        {
          path: 'authResponseTest',
          name: 'AuthResponseTest',
          meta: { requiresAuth: false },
          component: AuthResponseTest
        },
        { path: '*', redirect: '/pages/page404' }
      ]
    },
    { path: '/*', redirect: '/pages/page404' }
  ]
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  scrollBehavior: () => ({ x: 0, y: 0 }),
  linkActiveClass: 'active',
  routes: configRoutes()
})

router.afterEach(() => {
  setTimeout(() => {
    dispatch('setLoadingStatus', { status: false })
  }, 750)
})
router.onError(e => {
  if (e.toString() === 'Error: fromLogin') {
    dispatch('setLoadingStatus', { status: false })
  }
})

const getUserSelfHandler = async () => {
  if (state.user.self) return 200
  let response = await dispatch('user/getSelf')
  return response
}

router.beforeEach(async (to, from, next) => {
  await dispatch('setLoadingStatus', { status: true })

  const requiresAuth = to.meta.requiresAuth
  //不需要驗證
  if (!requiresAuth) {
    return next()
  }

  await dispatch('getToken') // done
  const getUserSelfStatusCode = await getUserSelfHandler()
  const userSelf = state.user.self

  //頁面驗證失敗
  if (getUserSelfStatusCode != 200) {
    return from.name === 'Login'
      ? next(new Error('fromLogin'))
      : next({ path: '/pages/login' })
  }

  // 使用者第一次登入 > 先修改密碼
  if (userSelf.signedInWithTemporaryPassword) {
    return to.name === 'PasswordSetup'
      ? next()
      : next({ path: '/pages/password/setup' })
  }

  const role = userSelf.role // [CLIENT, CUSTOMER_SUCCESS, SYSTEM_ADMIN]
  const isAdmin = to.meta.isAdmin // [CUSTOMER_SUCCESS, SYSTEM_ADMIN]
  //頁面是否有admin權限
  if (isAdmin) {
    if (role === 'CLIENT') {
      return next({ path: '/pages/page403' })
    }

    if (to.name === 'SubscriptionNew' || to.name === 'SubscriptionEdit') {
      return role !== 'SYSTEM_ADMIN' ? next({ path: '/pages/page403' }) : next()
    }

    if (to.name === 'UserEdit') {
      return role !== 'SYSTEM_ADMIN' ? next({ path: '/pages/page403' }) : next()
    }

    return next()
  }

  // NORMAL USER, NO ADMIN
  if (to.name === 'ConnectionsNew' && role !== 'CLIENT') {
    return next({ path: '/pages/page403' })
  }

  if (to.name === 'UserInfo' && role !== 'CLIENT') {
    return next({ path: '/pages/page403' })
  }

  return next()
})

Vue.use(VueRouter)

export default router
