import api from '../../api'

const state = () => ({
  accounts: undefined,
  adAccounts: undefined
})

const mutations = {
  setAccounts(state, payload) {
    state.accounts = payload
  },
  setAdAccounts(state, payload) {
    state.adAccounts = payload
  }
}

const actions = {
  async getOauthStart() {
    try {
      let response = await api.getFacebookOAuthStart()
      // console.log(response)
      return response
    } catch (err) {
      console.error('Facebook - getOauthStart: ' + err)
      return 'error'
    }
  },
  async getAccounts({ commit }) {
    try {
      const limit = 100
      let response = await api.getFacebookAccounts(limit)
      let total = response.data.total
      if (total > limit) {
        response = await api.getFacebookAccounts(total)
      }
      commit('setAccounts', response.data.results)

      return response.status
    } catch (err) {
      console.error('Facebook - getAccounts: ' + err)
      return 'error'
    }
  },
  async getAdAccounts({ commit }, payload) {
    try {
      const { id } = payload
      const limit = 100
      let response = await api.getFacebookAdAccounts(id, limit)
      let total = response.data.total
      if (total > limit) {
        response = await api.getFacebookAdAccounts(id, total)
      }
      commit('setAdAccounts', response.data.results)
      return response.status
    } catch (err) {
      console.error('Facebook - getAdAccounts: ' + err)
      commit('setAdAccounts', [])
      return 'error'
    }
  },
  async deleteAccount(store, payload) {
    try {
      const { id } = payload
      let response = await api.deleteFacebookAccount(id)
      return response.status
    } catch (err) {
      console.error('Facebook - deleteAccount: ' + err)
      return 'error'
    }
  },
  // Page - ConnectionSetup
  async getAccountDefault({ state, commit, dispatch }) {
    try {
      await dispatch('getAccounts')
      if (state.accounts.length) {
        commit(
          'connection/setEditSetup',
          {
            type: 'facebookAccountId',
            value: state.accounts[0].id
          },
          { root: true }
        )
        await dispatch('getAdAccounts', {
          id: state.accounts[0].id
        })
      }
      return 'success'
    } catch (err) {
      console.error('Facebook - getAccountDefault: ' + err)
      return 'error'
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
