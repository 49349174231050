<template>
  <div id="app">
    <router-view v-if="!loadingStatus"></router-view>
    <Loading
      :active.sync="loadingStatus"
      transition="fade"
      :opacity="1"
      background-color="#fff"
      class="loading"
    >
      <div class="loading-spinner">
        <div class="loading-animation">
          <div></div>
          <div></div>
        </div>
      </div>
    </Loading>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  data() {
    return {}
  },
  computed: {
    ...mapState(['loadingStatus'])
  },
  methods: {}
}
</script>

<style lang="scss">
@import '@/assets/main';
</style>
