import api from '../../api'

const state = () => ({
  list: undefined
})

const mutations = {
  setList(state, payload) {
    state.list = payload
  }
}

const actions = {
  async getList({ commit }) {
    try {
      const limit = 100
      let response = await api.getHistories(limit)
      let total = response.data.total
      if (total > limit) {
        response = await api.getHistories(total)
      }
      commit('setList', response.data.results)
      return response.status
    } catch (err) {
      console.error('History - getList: ' + err)
      return 'error'
    }
  },
  // Page - Home
  async pageHomeCreated({ dispatch }) {
    try {
      dispatch('progressStart', null, { root: true })

      if (!state.list) {
        let status = await dispatch('getList')
        if (status !== 200) throw 'error'
      }

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('History - pageHomeCreated: ' + err)
      return 'error'
    }
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations
}
