import api from '../../api'

const debug = process.env.NODE_ENV !== 'production'

const state = () => ({
  list: undefined,
  view: undefined,
  edit: undefined,
  fivestonesStaff: undefined,
  userList: undefined
})

const mutations = {
  setList(state, payload) {
    state.list = payload
  },
  setView(state, payload) {
    state.view = payload
  },
  initEdit(state) {
    state.edit = {
      companyName: undefined,
      validFrom: undefined,
      validUntil: undefined,
      maxNumOfActiveTasks: 1,
      fsContacts: []
    }
  },
  setEdit(state, payload) {
    const {
      companyName,
      validFrom,
      validUntil,
      maxNumOfActiveTasks,
      fsContacts
    } = payload
    state.edit = {
      companyName,
      validFrom,
      validUntil,
      maxNumOfActiveTasks,
      fsContacts
    }
  },
  setEditSetup(state, payload) {
    state.edit[payload.type] = payload.value
  },
  setFivestonesStaff(state, payload) {
    state.fivestonesStaff = payload
  },
  setUserList(state, payload) {
    state.userList = payload
  }
}

const actions = {
  async getList({ commit }) {
    try {
      const limit = 100
      let response = await api.getSubscriptions(limit)
      let total = response.data.total
      if (total > limit) {
        response = await api.getSubscriptions(total)
      }
      commit('setList', response.data.results)
      return response.status
    } catch (err) {
      console.error('Subscription - getList: ' + err)
      return 'error'
    }
  },
  async getView({ commit }, payload) {
    try {
      let response = await api.getSubscription(payload.id)
      commit('setView', response.data)
      return response.status
    } catch (err) {
      console.error('Subscription - getView: ' + err)
      return 'error'
    }
  },
  async createHandler({ state }) {
    try {
      let response = await api.createSubscription(state.edit)
      return response.status
    } catch (err) {
      console.error('Subscription - createHandler: ' + err)
      return 'error'
    }
  },
  async updateHandler({ state }, payload) {
    try {
      const necessaryToUpdate = obj => {
        const view = state.view
        let result = {}
        for (let key in obj) {
          view[key] !== obj[key] ? (result[key] = obj[key]) : ''
        }
        return result
      }
      let data = necessaryToUpdate(state.edit)
      let response = await api.updateSubscription(payload.id, data)
      return response.status
    } catch (err) {
      console.error('Subscription - updateHandler: ' + err)
      return 'error'
    }
  },
  async deleteHandler(store, { id }) {
    try {
      let response = await api.deleteSubscription(id)
      return response.status
    } catch (err) {
      console.error('Subscription - deleteHandler: ' + err)
      return 'error'
    }
  },
  async getFivestonesStaff({ commit }) {
    try {
      const limit = 100
      let response = await api.getFivestonesStaff(limit)
      let total = response.data.total
      if (total > limit) {
        response = await api.getFivestonesStaff(total)
      }
      commit('setFivestonesStaff', response.data.results)
      return response.status
    } catch (err) {
      console.error('Subscription - getFivestonesStaff: ' + err)
      return 'error'
    }
  },
  async getUserList({ commit }, { id }) {
    try {
      const limit = 100
      let response = await api.getSubscriptionUserList(id, limit)
      let total = response.data.total
      if (total > limit) {
        response = await api.getSubscriptionUserList(id, total)
      }
      commit('setUserList', response.data.results)
      return response.status
    } catch (err) {
      console.error('Subscription - getUserList: ' + err)
      return 'error'
    }
  },
  // Page - Home
  async pageHomeCreated({ state, dispatch }) {
    try {
      dispatch('progressStart', null, { root: true })

      if (!state.list) {
        let status = await dispatch('getList')
        if (status !== 200) throw 'error'
      }

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('Subscription - pageHomeCreated: ' + err)
      return 'error'
    }
  },
  async pageHomeDelete({ dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })

      const { id } = payload
      let deleteResponse = await dispatch('deleteHandler', { id })
      if (deleteResponse !== 200) throw 'error'

      let status = await dispatch('getList')
      if (status !== 200) throw 'error'

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('Subscription - pageHomeDelete: ' + err)
      return 'error'
    }
  },
  // Page - Setup
  async pageSetupCreated({ state, commit, dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })

      commit('initEdit')
      const { id } = payload
      if (id) {
        let status = await dispatch('getView', payload)
        if (status !== 200) throw 'error'
        commit('setEdit', state.view)
      }

      if (!debug) {
        if (!state.fivestonesStaff) {
          let status = await dispatch('getFivestonesStaff')
          if (status !== 200) throw 'error'
        }
      }

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('Subscription - pageSetupCreated: ' + err)
      return 'error'
    }
  },
  async pageSetupSubmit({ dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })

      const { status, id } = payload
      if (status === 'New') {
        let status = await dispatch('createHandler')
        if (status !== 201) throw 'error'
      } else if (status === 'Edit') {
        let status = await dispatch('updateHandler', { id })
        if (status !== 200) throw 'error'
      }

      let getListStatus = await dispatch('getList')
      if (getListStatus !== 200) throw 'error'

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('Subscription - pageSetupSubmit: ' + err)
      return 'error'
    }
  },
  // Page - View
  async pageViewCreated({ state, commit, dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })

      const { id } = payload
      if (state.list) {
        let subscription = state.list.filter(x => x.id === id)
        commit('setView', subscription[0])
      } else {
        let status = await dispatch('getView', { id })
        if (status !== 200) throw 'error'
      }
      let status = await dispatch('getUserList', { id })
      if (status !== 200) throw 'error'

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('Subscription - pageViewCreated: ' + err)
      return 'error'
    }
  },
  async pageViewUserDelete({ state, dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })
      const { id } = payload

      let status = await dispatch('user/deleteHandler', { id }, { root: true })
      if (status !== 200) throw 'error'

      let getListStatus = await dispatch('getUserList', { id: state.view.id })
      if (getListStatus !== 200) throw 'error'

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('Subscription - pageViewUserDelete: ' + err)
      return 'error'
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
