import api from '../../api'

const state = () => ({
  self: undefined,
  list: undefined,
  view: undefined,
  edit: undefined
})

const mutations = {
  setSelf(state, payload) {
    state.self = payload
  },
  setList(state, payload) {
    state.list = payload
  },
  setView(state, payload) {
    state.view = payload
  },
  initEdit(state) {
    state.edit = {
      role: 'CLIENT',
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      subscriptionId: undefined
    }
  },
  setEdit(state, payload) {
    const { role, firstName, lastName, email, subscription } = payload
    state.edit = {
      role,
      firstName,
      lastName,
      email,
      subscriptionId: subscription ? subscription.id : null
    }
  },
  setEditSetup(state, payload) {
    state.edit[payload.type] = payload.value
  },
  deleteEditSetup(state, payload) {
    delete state.edit[payload.type]
  }
}

const actions = {
  async getSelf({ commit }) {
    try {
      let response = await api.getUserSelf()
      commit('setSelf', response.data)
      return response.status
    } catch (err) {
      console.error('User - getSelf: ' + err)
      return 'error'
    }
  },
  async getList({ commit }) {
    try {
      const limit = 100
      let response = await api.getUsers(limit)
      let total = response.data.total
      if (total > limit) {
        response = await api.getUsers(total)
      }
      commit('setList', response.data.results)
      return response.status
    } catch (err) {
      console.error('User - getList: ' + err)
      return 'error'
    }
  },
  async getView({ commit }, payload) {
    try {
      let response = await api.getUser(payload.id)
      commit('setView', response.data)
      return response.status
    } catch (err) {
      console.error('User - getView: ' + err)
      if (err.response) return err.response.status
      return 'error'
    }
  },
  async createHandler({ state }) {
    try {
      const { role, firstName, lastName, email, subscriptionId } = state.edit
      const data = {
        role,
        firstName,
        lastName,
        email,
        subscriptionId
      }
      let response = await api.createUser(data)
      return response.status
    } catch (err) {
      console.error('User - createHandler: ' + err)
      return 'error'
    }
  },
  async updateHandler({ state }, payload) {
    try {
      const necessaryToUpdate = obj => {
        const view = state.view
        let result = {}
        for (let key in obj) {
          if (key !== 'subscriptionId') {
            view[key] !== obj[key] ? (result[key] = obj[key]) : ''
          } else {
            if (view.subscription) {
              view.subscription.id !== obj[key] ? (result[key] = obj[key]) : ''
            } else {
              view.subscription !== obj[key] ? (result[key] = obj[key]) : ''
            }
          }
        }
        return result
      }
      let data = necessaryToUpdate(state.edit)
      let response = await api.updateUser(payload.id, data)
      return response.status
    } catch (err) {
      console.error('User - updateHandler: ' + err)
      return 'error'
    }
  },
  async deleteHandler(store, payload) {
    try {
      let response = await api.deleteUser(payload.id)
      return response.status
    } catch (err) {
      console.error('User - deleteHandler: ' + err)
      return 'error'
    }
  },
  // Page - Reset Password
  async PasswordSetup({ state, dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })

      const data = payload
      let response = await api.updateUser(state.self.id, data)
      if (response.status !== 200) throw 'error'

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('User - PasswordSetup: ' + err)
      return 'error'
    }
  },
  // Page - Home
  async pageHomeCreated({ state, dispatch }) {
    try {
      dispatch('progressStart', null, { root: true })

      if (!state.list) {
        let status = await dispatch('getList')
        if (status !== 200) throw 'error'
      }

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('User - pageHomeCreated: ' + err)
      return 'error'
    }
  },
  async pageHomeDelete({ dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })
      const { id } = payload

      let deleteResponse = await dispatch('deleteHandler', { id })
      if (deleteResponse !== 200) throw 'error'

      let status = await dispatch('getList')
      if (status !== 200) throw 'error'

      dispatch('progressEnd', null, { root: true })

      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('User - pageHomeDelete: ' + err)
      return 'error'
    }
  },
  // Page - Setup
  async pageSetupCreated({ state, commit, dispatch, rootState }, payload) {
    try {
      dispatch('progressStart', null, { root: true })

      commit('initEdit')
      const { id, subscriptionId } = payload
      if (id) {
        let status = await dispatch('getView', payload)
        if (status !== 200) throw 'error'
        commit('setEdit', state.view)
      }

      if (!rootState.subscription.list) {
        let status = await dispatch('subscription/getList', null, {
          root: true
        })
        if (status !== 200) throw 'error'
      }

      // HAS Subscription
      if (subscriptionId) {
        let subscription = rootState.subscription.list.filter(
          x => x.id === subscriptionId
        )
        commit('setEditSetup', {
          type: 'subscriptionId',
          value: subscription[0].id
        })
      }

      dispatch('progressEnd', null, { root: true })

      return 'success'
    } catch (err) {
      dispatch('progressEnd', null, { root: true })
      console.error('User - pageSetupCreated: ' + err)
      return 'error'
    }
  },
  async pageSetupSubmit({ dispatch }, payload) {
    try {
      dispatch('progressStart', null, { root: true })

      const { status, id } = payload

      if (status === 'New') {
        let status = await dispatch('createHandler')
        if (status !== 201) throw 'error'
      } else if (status === 'Edit') {
        let status = await dispatch('updateHandler', { id })
        if (status !== 200) throw 'error'
        if (status === 403)
          return 'User is not allowed to update the specified user'
        if (status === 412)
          return 'User provided incorrect original password for password update request'
      }

      let getListStatus = await dispatch('getList')
      if (getListStatus !== 200) throw 'error'

      dispatch('progressEnd', null, { root: true })
      return 'success'
    } catch (err) {
      console.error('User - pageSetupSubmit: ' + err)
      dispatch('progressEnd', null, { root: true })
      return 'error'
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
