import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api'
import connection from './modules/connection'
import user from './modules/user'
import subscription from './modules/subscription'
import facebook from './modules/facebook'
import google from './modules/google'
import accountAuth from './modules/accountAuth'
import history from './modules/history'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  strict: debug,
  state: {
    loadingStatus: false,
    progressStatus: false,
    progress: 0,
    sidebarShow: 'responsive'
  },
  mutations: {
    loadingStatus(state, newLoadingStatus) {
      state.loadingStatus = newLoadingStatus
    },
    progressStatus(state, newProgressStatus) {
      state.progressStatus = newProgressStatus
    },
    updateProgress(state, progress) {
      state.progress = progress
    },
    toggleSidebarDesktop(state) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    setSidebarShow(state, value) {
      state.sidebarShow = value
    }
  },
  actions: {
    async getToken() {
      try {
        let response = await api.getToken()
        return response
      } catch (err) {
        console.error(err)
        return 'error'
      }
    },
    async login({ commit, dispatch }, payload) {
      try {
        dispatch('progressStart')

        await api.initToken()
        let response = await api.login(payload)
        commit('user/setSelf', response.data)

        dispatch('progressEnd')
        return response.status
      } catch (err) {
        dispatch('progressEnd')
        console.error('Index - Login: ' + err)
        return 'error'
      }
    },
    async logout({ dispatch }) {
      try {
        dispatch('progressStart')

        let response = await api.logout()
        await api.initToken()

        dispatch('progressEnd')
        return response.status
      } catch (err) {
        dispatch('progressEnd')
        console.error('Index - logout: ' + err)
        return 'error'
      }
    },
    async resetPassword({ dispatch }, payload) {
      try {
        dispatch('progressStart')

        let response = await api.resetPassword(payload)
        console.log(response)

        dispatch('progressEnd')
        return response.status
      } catch (err) {
        dispatch('progressEnd')
        console.error('Index - resetPassword: ' + err)
        return 'error'
      }
    },
    // Page - index
    async homepageMounted({ dispatch }) {
      try {
        let status = await dispatch('user/getSelf')
        if (status !== 200) throw 'error'

        return 'success'
      } catch (err) {
        console.error('Index - homepageMounted: ' + err)
        return 'error'
      }
    },
    progressStart({ commit, state }) {
      if (!state.progressStatus) {
        commit('progressStatus', true)
        let start = Math.floor(Math.random() * 30 + 1) + 10
        commit('updateProgress', start)
        setTimeout(() => {
          if (state.progress !== 100) {
            let end = Math.floor(Math.random() * (90 - 40 + 1)) + 40
            commit('updateProgress', end)
          }
        }, 250)
      }
    },
    progressEnd({ commit, state }) {
      if (state.progressStatus) {
        commit('updateProgress', 100)
        setTimeout(() => {
          commit('progressStatus', false)
        }, 1000)
      }
    },
    setLoadingStatus({ commit }, payload) {
      commit('loadingStatus', payload.status)
    }
  },
  modules: {
    connection,
    user,
    subscription,
    facebook,
    google,
    accountAuth,
    history
  }
})
